import React from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig";

import "./bannerOne.scss"

const BannerOne = () => {
  return (
    <div className="banner banner-style-1">
      <div className="container">
        <div className="row align-items-end align-items-xl-start">
          <div className="col-lg-6">
            <div className="banner-content">
            <div className="promo-container">
                <h1 className="title">
                  קבלו שליטה מלאה על דירוג האתר שלכם בגוגל
                </h1>
                <span className="subtitle">
                  גלו את מיקומי מילות המפתח שלכם, ראו איך האתר מתפקד מול מתחרים, צפו בדוחות מפורטים וגרפים אינטראקטיביים, וקבלו תובנות לשיפור הדירוג והתנועה לאתר שלכם                
                </span>

                <div className="cta-container">
                <a
                  href={"https://dashboard.weseo.app/register"}
                  className="axil-btn btn-fill-primary btn-large"
                  target="_blank"
                  aria-label="נפתח בטאב חדש"
                  onClick={() => {
                    logEvent(analytics, 'register_click', {
                      button_name: 'banner_register_button',
                      button_location: 'homepage-banner',
                      destination_url: 'https://dashboard.weseo.app/register'
                    });
                  }}
                >
                  הירשמו עכשיו
                </a>
                  <div className="promo-tag">
                    <span className="promo-text"> 14 ימים התנסות חינם - ללא כרטיס אשראי </span>
                    <span className="promo-icon">🎁</span>
                  </div>
                  </div>
              </div>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-thumbnail">
                <div className="large-thumb">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/banner/laptop-poses.png"
                    }
                    alt="Laptop"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-21">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-5">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-6">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"}
            alt="Bubble"
          />
        </li>
      </ul>
    </div>
  );
};

export default BannerOne;
