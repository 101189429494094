import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import TestimonialItem from "./TestimonialItem";

const TestimonialOne = () => {
  return (
    <>
        <SectionTitle
          subtitle="מה אנחנו מציעים?"
          title="הסבר על תכונות המערכת"
          textAlignment="heading-light"
          textColor=""
        />
        <TestimonialItem />
    </>
  );
};

export default TestimonialOne;
