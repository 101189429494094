import React, { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.rtl.min.css";

// Home Pages Import
import DigitalAgency from "./pages/DigitalAgency";
// Blog Import
import BlogGridView from "./pages/BlogGrid";
import BlogDetails from "./pages/BlogDetails";


// Pages
import ErrorPage from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

// Css Import
import "./assets/scss/app.scss";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("config", "G-4C0EMWYW5N", {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return (
      <ScrollToTop>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/"}
            element={<DigitalAgency />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/blog/"}
            element={<BlogGridView />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/blog/:id"}
            element={<BlogDetails />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/404"}
            element={<ErrorPage />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/privacy-policy"}
            element={<PrivacyPolicy />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/terms-use"}
            element={<TermsOfUse />}
          />
        </Routes>
      </ScrollToTop>
  );
};

export default App;
