import React from "react";
import { Link } from "react-scroll";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig";
import "./nav.scss"
const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
      <li>
        <div className="btn-register-small">
          <a
            href="https://dashboard.weseo.app/register"
            target="_blank"
            aria-label="נפתח בטאב חדש"
            onClick={() => {
              logEvent(analytics, 'register_click', {
                button_name: 'navbar_register_button',
                button_location: 'homepage-navbar',
                destination_url: 'https://dashboard.weseo.app/register'
              });
            }}
          >
            ליצירת חשבון
          </a>
          </div>
        </li>
        <li>
          <Link to="services" role="button">
            השירותים שלנו
          </Link>
        </li>
        <li>
          <Link to="prices" role="button">
            מחירים
          </Link>
        </li>
        <li>
          <Link to="stats" role="button">
            סטטיסטיקות
          </Link>
        </li>
        <li>
          <Link to="contact" role="button">
            צרו קשר
          </Link>
        </li>
        <li>
          <a
            href={"https://weseo.app/blog"}
            role="button"
            aria-label="נפתח באותו הטאב"
          >
            מאמרים
          </a>
        </li>
        <li>
          <a
            href={"https://dashboard.weseo.app/"}
            target="_blank"
            role="button"
            aria-label="נפתח בטאב חדש"
          >
            התחברות
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
