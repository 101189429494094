import React from "react";
import { FaLightbulb, FaMoon } from "react-icons/fa";

const SwitcherHeader = () => {
  return (
    <button>
      <span className="setColor light">
        <FaMoon />
      </span>
    </button>
  );
};

export default SwitcherHeader;
