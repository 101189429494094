import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BannerOne from "../component/banner/BannerOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import PricingOne from "../component/pricing/PricingOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import "./DigitalAgency.scss"

const DigitalAgency = () => {
  return (
    <>
      <SEO title="Digital Agency" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerOne />
        <div id="services" className="section section-padding-2 bg-color-dark">
          <div className="row">
            <TestimonialOne />
          </div>
        </div>

        <div className="section bg-color-light section-padding" id="prices">
          <div className="container">
            <SectionTitle
              subtitle="מחירון"
              title="בעזרת המוצר שלנו תגיעו רחוק"
              description="אצלנו יש חבילה לכל סוג של עסק, תוכלו לבחור את החבילה שמתאימה לכם ולהתחיל לקבל אנליזות ודוחות שיעזרו לקדם את העסק שלכם"
              textAlignment="center"
              textColor=""
            />
            <PricingOne />
          </div>
          <ul className="list-unstyled shape-group-3">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                alt="shape"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                alt="shape"
              />
            </li>
          </ul>
        </div>
        <CounterUpOne />

        <CtaLayoutOne />
        <FooterOne parentClass="" id="contact" />
      </main>
    </>
  );
};

export default DigitalAgency;
